<template>
  <div>
    <nav
      v-if="isLoggedIn && isInitiated"
      class="navbar-custom-menu"
    >
      <div class="navbar-item">
        <router-link
          :to="{ name: 'Dashboard'}"
          class="navbar-link icon-text is-arrowless"
        >
          <span class="icon is-small">
            <i class="fas fa-chevron-left"></i>
          </span>
          <span>Passport</span>
        </router-link>
      </div>
    </nav>
    <hr />

    <div class="contentWrapper">
      <h2 class="title is-3">Matches</h2>
      <p>Our AI algorithm matches you not only to people and experts, but also events, content and question being asked from the S2M ecosystem. Enjoy all the serendipity machine tailor made recommendations.</p>

      <hr class="separation" />
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="!isFirstLoad"
          v-masonry
          transition-duration="0.2s"
          item-selector=".column"
          class="is-multiline masonry-container"
        >
          <match-item
            v-for="match in matches"
            :key="match.Id"
            :match="match"
            :showLocation="true"
            :showTags="true"
            class="column is-half-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd"
          ></match-item>
        </div>
      </transition>

      <hr class="separator" />
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="isLoading"
          class="has-padding has-text-centered"
        >
          <img
            src="@/assets/img/s2m-loader-big.gif"
            alt="loader"
          >
        </div>
        <div
          v-else-if="!isLoading && !reachedEnd"
          class="has-padding has-text-centered"
        >
          <a
            @click="loadMore"
            class="button is-primary"
          >Load more</a>
        </div>
      </transition>
      <!-- <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="isLoading"
          class="padding has-text-centered"
        >
          <img
            src="@/assets/img/s2m-loader-big.gif"
            alt="loader"
          />
        </div>
      </transition> -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import match from '@/providers/match'
// import { VueMasonryPlugin } from 'vue-masonry'

// Vue.use(VueMasonryPlugin)

export default {
  data() {
    return {
      bottom: false,
      isLoading: false,
      isFirstLoad: true,
      itemsPerPage: 20,
      latitude: 0,
      longitude: 0,
      matches: [],
      page: 1,
      reachedEnd: false,
      workingon: ''
    }
  },

  computed: {
    ...mapGetters({
      currentCheckin: 'getCurrentCheckin',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn'
    })
  },

  watch: {
    // bottom(bottom) {
    //   if (bottom) {
    //     this.page = this.page + 1
    //     this.getMatches()
    //   }
    // },

    currentCheckin() {
      this.getMatches()
    }
  },

  created() {
    // window.scrollTo(0, 0)
    // window.addEventListener('scroll', () => {
    //   this.bottom = this.bottomVisible()
    // })
  },

  mounted() {
    this.getMatches()
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.updateOnlineStatus)

    this.$store.commit('setSelectedPublicProfile', {
      profileId: 0,
      view: 'detail'
    })
  },

  methods: {
    loadMore() {
      this.page = this.page + 1
      this.getMatches()
    },

    // bottomVisible() {
    //   const scrollY = window.scrollY
    //   const visible = document.documentElement.clientHeight
    //   const pageHeight = document.documentElement.scrollHeight
    //   const bottomOfPage = visible + scrollY >= pageHeight
    //   return bottomOfPage || pageHeight < visible
    // },

    getBackgroundClass(itemType) {
      let cls = ''
      switch (itemType) {
        case 0: // Check-in
          cls = 'has-background-cyan'
          break
        case 1: // Location
          cls = 'has-background-warning'
          break
        case 2: // magazine article
          cls = 'has-background-blue-dark'
          break
        case 3: // event
          cls = 'has-background-blue-dark'
          break
        case 4: // question
          cls = 'has-background-green'
          break
        default:
          break
      }
      return cls
    },

    getButtonClass(itemType) {
      let cls = ''
      switch (itemType) {
        case 0:
          break
        case 1:
          // chat
          cls = 'has-background-cyan'
          break
        case 2:
          // magazine article
          cls = 'is-blue-dark'
          break
        case 3:
          // event ??
          cls = 'has-background-blue-dark'
          break
        case 4:
          // question
          cls = 'is-success'
          break
        default:
          break
      }
      return cls
    },

    getButtonText(itemType) {
      let value = 'Read'
      switch (itemType) {
        case 0:
          break
        case 1:
          // chat
          value = 'Chat'
          break
        case 2:
          // magazine article
          value = 'Read'
          break
        case 3:
          // event ??
          value = 'See more'
          break
        case 4:
          // question
          value = 'Open question'
          break
        default:
          break
      }
      return value
    },

    getMatches() {
      let self = this
      let workingon = ''
      if (this.currentCheckin) {
        workingon = this.currentCheckin.WorkingOn
      }

      if (this.$route.query.q !== '' && this.$route.query.q !== undefined) {
        workingon = this.$route.query.q
      }

      if (
        !this.isLoading &&
        !this.reachedEnd &&
        workingon &&
        workingon.length > 0
      ) {
        this.isLoading = true
        match.methods
          .getMatches(
            workingon,
            this.latitude,
            this.longitude,
            this.page,
            this.itemsPerPage
          )
          .then(response => {
            self.matches = self.matches.concat(response.data)

            if (response.data.MorePages === 0) {
              self.reachedEnd = true
            }
          })
          .finally(() => {
            self.isLoading = false
            self.isFirstLoad = false
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/template.scss";
.masonry-container {
  margin: -$gap;
}
.match-item,
.match-item-content {
  @include rounded(10px);
}
.match-item-content {
  overflow: hidden;
}
</style>
